@use '../../../styles/variables';

.infoOptionCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

hr.readMarker {
  border-top: 1px solid #0dbd8b;
  border-bottom: 1px solid #0dbd8b;
  margin-top: 0;
  position: relative;
  top: -1px;
  z-index: 1;
  will-change: width;
  -webkit-transition: width easeinsine .4s 1s,opacity easeinsine .4s 1s;
  transition: width easeinsine .4s 1s,opacity easeinsine .4s 1s;
  width: 99%;
  opacity: 1;
}