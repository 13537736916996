@use '../../../styles/variables';

.textBoxCard {
  height: 100px;
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.textarea {
  resize: unset;
  outline: unset;
}

.button {
  height: 2rem;
  width: 2rem;
  background: #fff;
}