@use '../../../../styles/overrides' as functions;

.menu-notizia-container {
  .d-block {
    background: white
  }
}

.list-notizia {
  max-width: 16rem;
  list-style: none;
  background: transparent;

  @media screen and (max-width: 992px) {
    max-width: 100%;
    background: linear-gradient(#ffffff, 50%, #f8f9fa);
  }

}

.menu-items {
  border-left: 1px solid #C0C0C0;
  border-top: 1px solid #C0C0C0;
  min-width: calc(100% - 16rem);
}

.list-notizia .active {
  font-weight: bold;
  border-left: 2px solid functions.theme-color('primary');
}

.menu-notizia {
  position: -webkit-sticky;
  position: sticky;
  min-width: 16rem;

  @media screen and (max-width: 992px) {
    max-width: 100%;
  }
}

.menu-notizia {
  h6 {
    padding: 1rem;
  }

  button {
    border-top: 1px solid #C0C0C0;
    border-left: 0;
    border-right: 0;
    border-bottom: 0;
    background: transparent;

    @media screen and (max-width: 992px) {
      background: #ffffff;
    }
  }
}

.menu-notizia-separator {
  min-height: 5px;
  background: #C0C0C0;
}