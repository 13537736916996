#footer {
  background: #003274;
  color: #fff;
  padding-top: 48px;

}

#footer section .intestazione {
  margin-bottom: 8px;
}

/* #footer .logoimg { */
  /* display: inline-block; */
/* } */

#footer a {
  color: #fff;
}

#footer .logoimg #img-point {
  width: 10em;
  height: 100%;
}

#footer .logoimg #img-agid {
  width: 9em;
  height: 100%;
}

#footer h1 {
  color: #FFF;
  font-size: .778em;
  font-weight: 700;
  margin: 0;
  padding: 0 0 16px 0;
  border-bottom: 1px solid #796c31;
  line-height: 1em;
  text-transform: uppercase;
  vertical-align: middle;
}


#footer h4 svg.icon {
  width: 22px;
  height: 22px;
  opacity: .5;
  margin-right: 8px;
  fill: #fff;
}

#footer .footer-list {
  list-style-type: none;
  margin: 15px 0;
  padding: 0;
  font-size: .889em;
}

#footer .footer-list li a {
  padding: 4px 0;
  display: block;
  color: #fff;
}

#footer section {
  margin-bottom: 28px;
}

#footer p {
  margin: 16px 0;
  color: #fff;
  font-size: .889em;
}

#footer .social {
  margin: 16px 0;
}

#footer .social li {
  margin-right: 20px;
}

#footer .postFooter {
  font-size: .8889em;
  border-top: 1px solid #9c8c3e;
  padding: 20px 0;
  color: #b5a96e; 

}

#footer .link_piede_footer {
  max-width: none;
  float: none;
  margin: 0 0 20px 0;
}

#footer .postFooter a {
  color: #fff;
  margin: 0 5px;

}
