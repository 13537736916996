.root {
    align-items: center;
    display: grid;
    grid-template-columns: auto auto 1fr 1fr auto;
    height: 100px;
    padding-inline-end: 8px;
    /* width: 350px; */
    border-bottom: 1px solid black;
}

.root:hover {
    background-color: lightgray;
}

.expandIconWrapper {
    align-items: center;
    /* font-size: 0; */
    cursor: pointer;
    display: flex;
    height: 24px;
    justify-content: center;
    width: 24px;
    transition: transform linear 0.1s;
    transform: rotate(0deg);
}

.expandIconWrapper.isOpen {
    transform: rotate(90deg);
}

.labelGridItem {
    justify-content: center;
    height: 24px;
    padding-inline-start: 5px;
}

.actionButton {
    padding: 0 4px;
}

.inputWrapper {
    align-items: center;
    display: grid;
    grid-template-columns: repeat(3, auto);
    justify-content: flex-start;
}

.textField input {
    font-size: 14px;
    padding: 6px 0;
}

.editButton {
    padding: 6px;
}

.editIcon {
    font-size: 20px;
}

.nodeInput {
    width: 200px;
}

.nodeLabel {
    padding-right: 15px;
}