.Logo-img {
    vertical-align: middle;
    width: 12em;
    height: 100%;
}

.Site-name {
    display: inline-block;
    width: 7em;
    vertical-align: middle;
    text-decoration: none !important;
    color: #fff !important;
}