@use '../../../styles/overrides' as functions;
@use '../../../styles/variables';

.roomsListCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.roomsList {
  overflow-y: overlay;
  min-height: 30rem;
  max-height: 30rem;
  padding: 2rem;
}

.roomsItem {
  color: #000;
  background: #fff;
  cursor: pointer;
  border-radius: 1rem;
  margin: 1rem 0;
  border: 1px solid rgba(0, 0, 0, 0.125);
  box-shadow: 0 0.125rem 0.25rem rgb(0 0 0 / 8%);
}

.roomsItemActive {
  background: functions.theme-color('primary');;
  color: #fff;
}

.roomName {
  text-align: initial;
}

.roomArgument {
  text-align: initial;
}