@use '../../../styles/overrides' as functions;
@use '../../../styles/variables';

.badgeUserCircle {
  background: functions.gray("300");
  color: functions.theme-color('dark');
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
}

.boardCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.bodyBoardCard {
  overflow-y: overlay;
  height: 23.6rem;
}

.button {
  height: 2rem;
  // width: 2rem;
  padding-right: 0%;
  background: #fff;
}