@use '../../../../styles/variables';
@use '../../../../styles/overrides' as functions;

.newsCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.aggiungiNewsCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.createNewsButton:disabled {
  color: functions.gray('400');
  background: functions.gray('100');
}

.createNewsButton {
  color: functions.color('white');
  background: functions.theme-color('primary');
}