.app {
  height: 100%;
}

.treeRoot {
  height: 100%;
}

.draggingSource {
  opacity: 0.1;
}

.dropTarget {
  background-color: #e8f0fe;
}

ul {
  list-style-type: none;
}