#search_form {
    height: 2em;
    word-wrap: break-word;
    border: 1px solid black;
 }
 
 #search_form th {
   border: solid 1px black
 }
 
 #search_form td {
   border: solid 1px black
 }