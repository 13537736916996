@use '../../../styles/overrides' as functions;
@use '../../../styles/variables';

.manageRoomCard {
  border-radius: 1rem;
  box-shadow: variables.$box-shadow;
}

.peopleListCard {
  overflow-y: overlay;
  height: 20rem;
}

.peopleList {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

.personBadge {
  background: functions.gray("300");
  color: functions.theme-color('dark');
  border-radius: 50%;
  font-weight: 600;
  display: flex;
  align-items: center;
  height: 2.5rem;
  width: 2.5rem;
  justify-content: center;
}