.container-single-title{
	background-color: #D5E2F2;  /* #e3f2fc */
	padding: 3em 0 2em 0;
}

.container-single-title hr{
	width: 200px;
	border-top: solid 2px #666;
	margin-top: 1em;
}

.container-single-title p.post-date{
	margin-top: 5em;
	font-weight: 600;
  background-repeat: no-repeat;
  background-size: 15px;
  background-position: 0px 8px;
  padding-left: 20px
}

.opere .container-scheda-opera {
	border-top: solid 1px #c1272d;
}

.opere .background-container-scheda-opera{
	background-repeat: no-repeat;
	background-position: top left;
}

.opere .scheda-opera{
	background: #e9ecef;
	padding-left: 1em;
	font-size: 1.2em;
}

.opere .descrizione-opera{
	background: #f9f9f9;
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {

	dt {
		float: left;
		width: 45%;
		/*text-align: right;*/
		padding: .25em .25em .25em 25px;
		clear: left;
	  background-repeat: no-repeat;
	  background-size: 20px;
	  background-position: 0px 10px;
	  border-top:solid 1px #ccc;

	}
	dd {
		float: left;
		width: 50%;
		padding: .25em 0;
		border-top:solid 1px #ccc;
	}
	dl:after {
		content:"";
		display:table;
		clear:both;
	}

}